@import '~@pgl-apps/styles/local.scss';

.label {
  text-transform: uppercase;
}

.input {
  border: 1px solid map-get($colors-layouts, 'dark');
  padding: 1rem 2rem;
  font-size: map-get($font-sizes, 'xl');
  color: var(--color-text-highlighted);
  outline: 0;

  &:focus,
  &:focus-visible {
    border-color: map-get($colors-layouts, 'border');
  }
}
