@import '~@pgl-apps/styles/local.scss';

.btn {
  display: flex;

  &__disabled {
    opacity: 0.3;
  }

  &__left {
    transform: rotate(180deg);
  }
}
