@import '~@pgl-apps/styles/local.scss';

.wrapper {
  border: 1px solid map-get($colors-layouts, 'border');
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -1px; // to overlay border
  margin-right: -1px;
}

.body {
  display: flex;
  align-items: center;
  margin: 4rem 2rem;
}

.bodyItem {
  flex: 0 0 50%;
}

.footer {
  margin-bottom: -1px; // to overlay border
  margin-right: -1px;
  display: flex;
  justify-content: flex-end;
}
