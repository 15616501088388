@import '~@pgl-apps/styles/local.scss';

$angle-space: 5rem;

.wrapper {
  position: relative;
  padding-left: $angle-space;
  overflow: hidden;
}

.inner {
  background: map-get($colors-layouts, 'primary-gradient');
  flex-basis: 14rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.angle {
  position: absolute;
  left: 0;
  right: calc(100% - #{$angle-space});
  top: 0;
  bottom: 0;
  background: map-get($colors-layouts, 'primary-gradient');
  transform: skewY(45deg);
  transform-origin: bottom right;
}

.label {
  font-size: map-get($font-sizes, 'sm');
  text-transform: uppercase;
}

.value {
  font-size: map-get($font-sizes, 'lg');
  margin-left: 1rem;
}
