@import '~@pgl-apps/styles/local.scss';

.knob {
  fill: map-get($colors-light-layout, 'background');
  stroke: 2px solid map-get($colors-light-layout, 'border-light');

  &:focus,
  &__active {
    .circle {
      stroke: map-get($colors-light-layout, 'border');
    }
  }
}

.circle {
  fill: map-get($colors-light-layout, 'background');
  stroke-width: 2px;
  stroke: map-get($colors-light-layout, 'border-light');
}

.rect {
  fill: map-get($colors-translucent, 'black-20');
}
