@import '~@pgl-apps/styles/local.scss';

.navItem {
  color: var(--color-text-base);
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 10px;
  margin-left: 1rem;
  font-weight: $font-weight-normal;

  &__active {
    color: var(--color-text-highlighted);
    font-weight: $font-weight-bold;
  }
}
