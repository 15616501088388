@import '~@pgl-apps/styles/local.scss';

$tick-height: 1px;
$tick-width: 1rem;
$tick-offset: 0.5rem;

.main {
  position: relative;
  width: 100%;
  max-width: 15rem;
  margin-left: auto;

  &::before {
    padding-top: 100%;
    display: block;
    content: '';
  }
}

.nob,
.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nob,
.trigger {
  border: 1px solid map-get($colors-layouts, 'border');
  border-radius: 50%;
}

.nob {
  transform: rotate(45deg);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tick {
  position: absolute;
  top: calc(50% - #{$tick-height * 0.5});
  left: -1 * ($tick-width + $tick-offset);
  width: calc(50% + #{$tick-width + $tick-offset});
  height: $tick-height;
  transform-origin: center right;

  .tickMark {
    background: map-get($colors-layouts, 'border');
    width: $tick-width;
    height: 100%;
  }
}

.trigger {
  position: absolute;
  width: 15%;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  border-width: 2px;

  &::before {
    padding-top: 100%;
    display: block;
    content: '';
  }
}

.number {
  font-size: map-get($font-sizes, 'lg');
  color: var(--color-text-highlighted);
}
