@import '~@pgl-apps/styles/local.scss';

.close {
  :global {
    .up-part,
    .down-part {
      transition: transform 1s;
    }
  }

  @include state(inactive) {
    :global {
      .up-part {
        transform: rotate(90deg);
      }
      .down-part {
        transform: rotate(-90deg);
      }
    }
  }
}
