.axisColors {
  stroke: var(--secondary-text);
}

// hiding the axis line and tick marks
.axisColors path {
  stroke: none;
}

.axisColors line {
  stroke: none;
}
