@import '~@pgl-apps/styles/local.scss';

.progressWrapper {
  position: relative;

  .blueBorder {
    border-top: 3px solid rgb(var(--color-lightsaber));
  }

  .blueGradient {
    height: 85px;
    background: transparent
      linear-gradient(1deg, #ffffff00 0%, #3baeae1c 49%, #34abab50 100%) 0% 0%
      no-repeat padding-box;
    opacity: 0.39;
  }

  .progress {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 0.625rem;

    .startLabel {
      padding-right: 3rem;
    }

    .endLabel {
      padding-left: 3rem;
    }

    .active {
      height: 0.625rem;
      background-image: url('./active.png');
    }

    .inactive {
      flex-grow: 1;
      height: 0.625rem;
      background-image: url('./inactive.png');
    }
  }

  .knobWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: -5px;

    .knob {
      width: 37px;
      height: 23px;
      border: 2px solid rgb(var(--color-primary-cta-border));
      border-radius: 4px;
    }

    .indicator {
      position: relative;
      min-width: 9rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgb(var(--color-primary-cta-border));
      border-radius: 6px;
      box-shadow: 0px 3px 6px #34373b1a;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        border: 10px solid transparent;
        top: 100%;
      }

      &::before {
        border-top-color: rgb(var(--color-primary-cta-border));
      }

      &::after {
        border-top-color: rgb(var(--color-container-bg));
        margin-top: -1px;
      }

      .label {
        font-size: 13px;
      }
      .totalEmission {
        font-size: 13px;
        text-align: center;
      }
      .kapAmount {
        font-size: 21px;
        font-weight: 600;
        margin-top: 6px;
        margin-bottom: 6px;
        line-height: 29px;
      }
    }
  }
}
