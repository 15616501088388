// --------------------- ===
//  NOTES
// ---------------------
// This should all be deprecated.
// Currently being used in some shared components, but those components
// should switch to themeColors

// --------------------- ===
//  PRIMARY COLORS
//  Don't reference these outside this file
// ---------------------
$colors-light: (
  'white': #fff,
  'cararra': #efefef,
  // 16:1 on 000
  'sand': #f4e3a0,
  'early-dawn': #fff8ea,
  'silver': #bebebe,
  // 8.92:1 on 101010
  'whiskey': #d2ac6a,
  // 10:1 on 000
  'harvest-gold': #d5b877,
);

$colors-dark: (
  // 8.59 on fff
  'mojo': #c63e3e,
  // err 2.53:1 on fff - APCA looks better
  'roman-gold': #d0992e,
  // 3.44 on fff (good for lg text) - #936E2F is 4.65
  'copper': #ae8337,
  'gray': #707070,
  'black': #101010,
  // extra black
  'thunder': #171717
);

$colors-decoration: (
  'success': #07bc0c,
  'error': #e74c3c,
  'warning': #f1c40f,
  'info': #3498db,
  // drop shadow
  'ghost': rgba(48, 49, 52, 0.2),
  'lightsaber': #309c9c,
);

// DEFINE ALL USES BELOW

// --------------------- ===
//  TRANSLUCENTS
// ---------------------
$colors-translucent: (
  'black-10': rgba(0, 0, 0, 0.1),
  'black-20': rgba(0, 0, 0, 0.2),
  'black-30': rgba(0, 0, 0, 0.3),
  'black-40': rgba(0, 0, 0, 0.4),
  'black-50': rgba(0, 0, 0, 0.5),
  'black-60': rgba(0, 0, 0, 0.6),
  'black-70': rgba(0, 0, 0, 0.7),
  'black-80': rgba(0, 0, 0, 0.8),
  'black-90': rgba(0, 0, 0, 0.9),
  'black-100': rgba(0, 0, 0, 1),
  'white-10': rgba(255, 255, 255, 0.1),
  'white-20': rgba(255, 255, 255, 0.2),
  'white-30': rgba(255, 255, 255, 0.3),
  'white-40': rgba(255, 255, 255, 0.4),
  'white-50': rgba(255, 255, 255, 0.5),
  'white-60': rgba(255, 255, 255, 0.6),
  'white-70': rgba(255, 255, 255, 0.7),
  'white-80': rgba(255, 255, 255, 0.8),
  'white-90': rgba(255, 255, 255, 0.9),
  'white-100': rgba(255, 255, 255, 1),
);

// --------------------- ===
//  COLORS TEXT
// ---------------------
$colors-text: (
  'black': map-get($colors-dark, 'black'),
  // for use on white
  'silver': map-get($colors-light, 'silver'),
  'white': map-get($colors-light, 'white'),
  // should only be on black
  'gold': map-get($colors-light, 'whiskey'),
  // can be on white
  'dark-gold': map-get($colors-dark, 'copper'),
  'error': map-get($colors-dark, 'mojo'),
);

// --------------------- ===
//  LAYOUTS
// ---------------------
$colors-layouts: (
  'background': map-get($colors-light, 'cararra'),
  'border': map-get($colors-light, 'whiskey'),
  'primary-gradient':
    linear-gradient(
      0deg,
      map-get($colors-light, 'sand') 0%,
      map-get($colors-light, 'harvest-gold') 100%
    ),
  'dark': map-get($colors-dark, 'black'),
);

$colors-light-layout: (
  'background': map-get($colors-light, 'white'),
  'text': map-get($colors-text, 'black'),
  'border': map-get($colors-dark, 'copper'),
  'border-light': map-get($colors-light, 'harvest-gold'),
);

$colors-dark-layout: (
  'background': map-get($colors-dark, 'black'),
  'text': map-get($colors-text, 'white'),
  'border': map-get($colors-light, 'whiskey'),
);

$colors-notification: (
  'success': map-get($colors-decoration, 'success'),
  'error': map-get($colors-decoration, 'error'),
  'warning': map-get($colors-decoration, 'warning'),
  'info': map-get($colors-decoration, 'info'),
);

$colors-tags: (
  'default': map-get($colors-dark, 'black'),
  'defaultBg': map-get($colors-light, 'cararra'),
  // doesn't meet contrast requirements
  'highlight': map-get($colors-light, 'whiskey'),
  'highlightBg': map-get($colors-light, 'early-dawn'),
  // no bg
  'none': map-get($colors-dark, 'black'),
);

$colors-header: (
  'border': map-get($colors-translucent, 'black-20'),
);

$colors-cta: (
  'background': map-get($colors-dark, 'thunder'),
  'text': map-get($colors-text, 'white'),
  'border': map-get($colors-light, 'whiskey'),
  'text-hover': map-get($colors-light, 'whiskey'),
);

$color-teal: map-get($colors-decoration, 'lightsaber');
$color-subtle-bg: map-get($colors-translucent, 'black-10');
$color-shadow: rgba(180, 180, 159, 0.3);
