@import '~@pgl-apps/styles/local.scss';

.btn {
  font-size: map-get($font-sizes, 'lg');
  padding: 2rem;

  &__current {
    text-decoration: underline;
  }

  @include media-breakpoint-up('lg') {
    .btn {
      font-size: map-get($font-sizes, 'xl');
    }
  }
}
