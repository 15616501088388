// --------------------- ===
//  GLOBAL VARS
//  safe to use across all projects
// ---------------------

// --------------------- ===
//  TABLES
// ---------------------
$table-row-height: 3rem;
$table-padding-x: 1rem;
$table-action-width: 2rem;
$table-action-margin: 0.25rem;
$table-dropdown-width: 2rem;
$table-select-width: 2rem;
$table-min-row-height: 3rem;
$table-row-gap: 0.75rem;

// --------------------- ===
//  MODAL
// ---------------------
$close-width: 1rem;
$close-top: -$close-width * 2;
$close-space: $close-width * 4;
