@import '~@pgl-apps/styles/local.scss';

// Mostly a duplicate of CtaFlat
// Should refactor after final designs
$icon-size: 0.65rem;
$icon-space: $icon-size * 2;
$icon-m: calc(($icon-space - $icon-size) / 2);
$box-padding-y: 0.75rem;
$box-offset: 0.2rem;
$border-width: 1px;

@include namespace {
  .btn {
    @apply bg-theme-primaryCtaBg text-theme-primaryCtaText border-theme-primaryCtaBorder relative transition-all items-center w-full py-3 px-12 uppercase text-center text-xs font-medium;

    &__disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      .icon {
        @apply right-3;
      }

      &::before {
        @apply bg-theme-primaryCtaBg;
      }
    }

    &::before {
      @apply absolute -top-1 -bottom-1 right-0 left-0 border border-theme-highlighted transition-colors;
      content: '';
    }
  }

  .icon {
    @apply transition-all absolute right-4 top-1/2 -translate-y-1/2 w-3;
  }
}
