@import '~@pgl-apps/styles/local.scss';

.row {
  @apply relative text-theme-tableText items-start;
  gap: $table-row-gap;

  @include state(header) {
    @apply flex bg-theme-tableHeaderBg text-theme-tableHeaderText text-sm uppercase rounded font-bold;

    .inner {
      // With header bg is on row,
      @apply bg-transparent border-0;
      // height: $table-row-height;
    }
  }

  @include state(action) {
    padding-right: calc($table-action-width + $table-action-margin);
  }

  @include state(expand) {
    .inner {
      padding-right: $table-dropdown-width;
    }
  }

  @include state(select) {
    .inner {
      padding-left: $table-select-width;
    }
  }

  @include state(expanded) {
    .inner {
      height: auto;
      min-height: $table-row-height;
    }
  }

  @include state(selected) {
    .inner {
      @apply border-theme-highlighted;
    }
  }
}

.inner {
  @apply grid w-full bg-theme-tableBg items-center border border-theme-tableBg rounded overflow-hidden;
  padding-right: $table-padding-x;
  padding-left: $table-padding-x;
  // height: $table-row-height;
}
