.wrapper {
  border: 1px solid rgb(var(--color-primary-cta-border));
  padding: 1.75rem 1rem 1rem 1rem;
}

.splitter {
  border-left: 1px solid rgb(var(--color-primary-cta-border));
}

.boostValue {
  color: rgb(var(--color-text-highlighted));
}
