// THESE ARE ONLY BEING USED IN kap-stake
// they should be deprecated in favor of tailwind vars

// We will likely use a new _variables.scss file for some shared definitions

// TEMP FOR TRANSITION
$color-shadow: rgba(180, 180, 159, 0.3);
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);
$gutters: $spacers;
$grid-gutter-width: 1.5rem;
// END TEMP

// --------------------- ===
//  LAYOUTS
// ---------------------
$shadows: (
  'section': 3px 3px 3px $color-shadow,
);

$neu-shadow-depth: (
  'normal': 8px,
);

$radii: (
  'bar': 0.25rem,
  'input': 0.2rem,
  'card': 0.5rem,
);

// --------------------- ===
//  COMPONENTS
// ---------------------
$notification-cards: (
  'offset': calc($grid-gutter-width / 2),
  'width': 18rem,
  // space for icon
  'padding-left': 3rem,
  // space for close
  'padding-right': 2rem,
);

$content-box: (
  'gutter': map-get($gutters, 2),
  'padding': map-get($gutters, 3),
);

$header-padding: 1rem;

$modal-width: 40rem;
$modal-max-width: calc(100% - $grid-gutter-width);

// --------------------- ===
//  Z-INDEX
// ---------------------
$z-indexes: (
  'bg': -1,
  'lift': 9,
  'header': 14,
  'modal': 19,
  'notifications': 29,
);

// --------------------- ===
//  TYPOGRAPHY
// ---------------------
// $font-family-sans-serif: 'exo', 'Helvetica Neue', sans-serif;
$font-family-sans-serif: 'inter', 'Helvetica Neue', sans-serif;
$font-family-serif: serif;

$base-font-family: $font-family-sans-serif;
$heading-font-family: 'Transducer', $font-family-serif;

$font-weight-normal: 400;
$font-weight-bold: 800;

$font-sizes: (
  'sm': 0.75rem,
  'md': 0.9rem,
  'lg': 1.2rem,
  'xl': 2rem,
  'xxl': 3rem,
);

// --------------------- ===
//  TABLES
// ---------------------
$table-padding-x: 15px;
