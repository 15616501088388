@import '~@pgl-apps/styles/local.scss';

.wrapper {
  display: flex;
  align-items: center;
  padding: $header-padding $grid-gutter-width;
}

.logo {
  display: flex;
  flex-basis: 150px;
  flex-grow: 0;
}

.trigger {
  display: flex;
  width: 25px;
  margin-right: 20px;
}
