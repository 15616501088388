// SCSS NOTE: alias imports seem to not work
// https://github.com/nrwl/nx/issues/1542
@import '~@pgl-apps/styles/local.scss';

$icon-size: 0.65rem;
$icon-space: $icon-size * 2;
$icon-m: calc(($icon-space - $icon-size) / 2);
$box-padding-y: 0.75rem;
$box-offset: 0.2rem;
$border-width: 1px;

.btn {
  @include standard-transition(border-color);
  position: relative;
  display: flex;
  align-items: center;
  padding: $box-padding-y $icon-space;
  color: var(--color-text-base);
  border: $border-width solid map-get($colors-translucent, 'black-20');
  border-radius: 0.25rem; // make var
  background: map-get($colors-translucent, 'white-100');
  font-size: map-get($font-sizes, 'sm');

  &__disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    border-color: map-get($colors-translucent, 'black-40');
  }

  &::before {
    content: '';
    position: absolute;
    top: calc($box-offset - $border-width);
    right: -$border-width;
    bottom: calc($box-offset - $border-width);
    left: -$border-width;
    border: $border-width solid map-get($colors-translucent, 'black-20');
    border-radius: 0.25rem; // make var
  }
}

.icon {
  margin-left: $icon-m * -1;
  margin-right: $icon-m;
  max-width: $icon-size;
  max-height: $icon-size;
}
