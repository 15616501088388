@import '~@pgl-apps/styles/local.scss';

$icon-space: 3rem;
$close-space: 3rem;

.card {
  @apply w-80 shadow-sm bg-white text-black rounded-innerBox max-w-full mb-4 p-6;
  padding-left: $icon-space;
  padding-right: $close-space;
  position: relative;
  display: none; // keeps card from flashing
  overflow: hidden;

  &__success {
    .icon {
      // @apply fill-theme-success;
      @apply fill-green-500; // TODO none of these need to be themed since cards are generic
    }
    .bar {
      // @apply bg-theme-success;
      @apply bg-green-500; // TODO none of these need to be themed since cards are generic
    }
  }

  &__error {
    .icon {
      @apply fill-theme-error;
    }
    .bar {
      @apply bg-theme-error;
    }
  }

  &__warning {
    .icon {
      @apply fill-theme-warning;
    }
    .bar {
      @apply bg-theme-warning;
    }
  }

  &__info {
    .icon {
      @apply fill-theme-info;
    }
    .bar {
      @apply bg-theme-info;
    }
  }
}

.icon {
  position: absolute;
  left: calc(#{$icon-space} / 4);
  top: 50%;
  width: calc(#{$icon-space} / 2);
  transform: translateY(-50%);
}

.close {
  @apply transition-transform;
  position: absolute;
  right: calc(#{$close-space} / 4);
  top: 0.5rem;
  width: calc(#{$close-space} / 2);
  padding: calc(#{$close-space} / 10);

  &:hover {
    transform: scale(1.05);
  }
}

.bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.25rem;
  width: 100%;
}
