@import '~@pgl-apps/styles/local.scss';

.outer {
  @apply bg-theme-bg text-theme-base rounded relative flex;
  // TODO: Double check if removing width is okay.
  // width: $modal-width;
  max-height: 100%;
  text-align: center;
  margin: 0 auto;
  overflow: visible;
}

.content {
  @apply p-4 md:p-8 w-full overflow-auto bg-theme-containerBg rounded;
}

.closeBtn {
  position: absolute;
  top: $close-top;
  right: $close-width;
  width: $close-width;
  display: flex;
}
