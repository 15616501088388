@import '~@pgl-apps/styles/local.scss';

.line {
  stroke: currentColor;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 10px;
}

.circle {
  fill: currentColor;
}
