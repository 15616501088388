// // @import '~@pgl-apps/styles/local.scss';
@import '~@pgl-apps/styles/local.scss';

$size: 60px;

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 3rem;

  &__pending {
    .disc {
      animation-iteration-count: infinite;
    }
  }

  &__complete {
    .disc {
      @apply stroke-theme-success;
      animation-iteration-count: 1;
    }
  }

  &__failed {
    .disc {
      @apply stroke-theme-error;
      animation-iteration-count: 1;
    }
  }
}

.disc {
  @apply fill-transparent stroke-theme-warning;
  position: absolute;
  // width: $size;
  // height: $size;
  // border: ($size / 10) solid map-get($colors-decoration, 'warning');
  opacity: 0.4;
  // border-radius: 100%;
  animation-fill-mode: forwards;
  perspective: 800px;
  transition: border-color 1s;
}
