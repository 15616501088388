$thickness: 6px;
$z: $thickness * 0.5;
// $gradient: linear-gradient(#cec092, #6e5934);
$glow: drop-shadow(0 0 5px #d8e51d);
$glow-error: drop-shadow(0 0 5px #d45858);

.wrapper {
  perspective: 1000px;
}

.inner {
  transform-style: preserve-3d;

  &::before {
    bottom: 0;
    top: 0;
    content: '';
    left: calc(50% - #{$z});
    position: absolute;
    width: $thickness;
    transform: rotateY(-90deg);
    // background: $gradient;
  }
}

.front,
.back {
  backface-visibility: hidden;

  img {
  }
}

.front {
  transform: translateZ($z);
}

.back {
  transform: rotateY(180deg) translateZ($z);
}

.frontInner,
.backInner {
  border-radius: 999px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // background: $gradient;
  filter: $glow;

  &__err {
    filter: $glow-error;
  }
}

.frontInner {
  transform: translateZ($z - 1px);
}

.backInner {
  transform: translateZ(-$z + 1px);
}

.slotWrapper {
  perspective: 100px;
}

.slot {
  transform: rotateX(65deg);
  box-shadow: 0 -0.5rem 1rem #f6e24f;
}
