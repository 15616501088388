@import '~@pgl-apps/styles/local.scss';

.main {
  background: #000;
  color: #fff;
  background-image: map-get($colors-layouts, 'background')
    url('@pgl-apps/shared-images/decoration/noisy-texture-200x200.png');
}

.primary {
  position: relative;
  min-height: calc(100vh - 6.5rem);
}

.circle {
  position: absolute;
  bottom: 20rem;
  width: 40%;

  &__left {
    left: 0;
    transform: translateX(-50%);
  }

  &__right {
    right: 0;
    transform: translate(50%) scale(-1, 1);
  }
}

.dashes {
  position: fixed;
  top: 50vh;
  width: 10px;
  transform: translateY(-50%);
  opacity: 0.2;

  &__left {
    left: 15px;
  }

  &__right {
    right: 15px;
  }
}
