@import '~@pgl-apps/styles/local.scss';

@include namespace {
  .cell {
    @apply tracking-wide;
    height: $table-row-height;

    & * {
      // This might be dangerous
      // Ensures 'ellipsis' works even if text is many components down
      @apply overflow-visible whitespace-normal bpTable:truncate;
    }

    &:not(.cell__header) {
      // Could be others that need this
      // Also might not be a great solution
      & button {
        @apply w-full;
      }
    }

    @media (min-width: theme('screens.bpTable')) {
      height: auto;
      min-height: $table-row-height;
    }

    @include state(expanded) {
      @apply overflow-visible whitespace-normal;
      height: auto;
      min-height: $table-row-height;

      & * {
        @apply overflow-visible whitespace-normal;
      }
    }

    @include state(header) {
      & * {
        @apply truncate;
      }
    }
  }
}
