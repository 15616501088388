@import '~@pgl-apps/styles/local.scss';

$turn-width: 12rem;
$line-height: 2px;
$line-offset: 5px;

.wrapper {
  position: absolute;
  right: $grid-gutter-width;
  top: 0;
  bottom: $header-padding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 1px;
  border-radius: 0 0 map-get($radii, 'bar') map-get($radii, 'bar');
  padding: 0 1rem;
}

.icon {
  flex: 0 0 10px;
  fill: currentColor;
  margin-right: 0.5rem;
  // margin-bottom: $line-height + $line-offset;
}

.id {
  display: flex;
  // margin-right: $grid-gutter-width;
  font-weight: bold;
  // margin-bottom: $line-height + $line-offset;
  overflow: hidden;
}

.idL {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 7ch;
}
