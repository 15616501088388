.badgeWrapper {
  color: rgb(var(--color-text-base));
  border-radius: 1.5rem;
  padding: 0.125rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.success {
  background-color: rgb(var(--color-success));
}
.error {
  background-color: rgb(var(--color-error));
}
.warning {
  background-color: rgb(var(--color-warning));
}
.info {
  background-color: rgb(var(--color-info));
}
.inactive {
  background-color: rgb(var(--color-text-muted));
}
