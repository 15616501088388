@import '~@pgl-apps/styles/local.scss';

@include namespace {
  .btn {
    @apply flex px-6 py-3 rounded text-theme-base;

    :global {
      .fill {
        @apply fill-transparent;
      }
      .stroke {
        @apply fill-theme-muted;
      }
    }

    &:hover {
      @apply bg-theme-containerBg bg-opacity-80;
    }

    &__active {
      @apply bg-theme-containerBg text-theme-highlighted;
      :global {
        .fill {
          @apply fill-theme-highlighted;
        }
        .stroke {
          @apply fill-transparent;
        }
      }
    }
  }
}
