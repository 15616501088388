// @import '~@pgl-apps/styles/local.scss';
@import '~@pgl-apps/styles/local.scss';

@include namespace {
  .action {
    @apply absolute right-0 top-0;
    width: $table-action-width;
    height: calc(#{$table-row-height} + 2px); // 2px to account for row border

    @include state(down) {
      .img {
        @apply opacity-100;
      }

      .bucket {
        @apply flex;
      }
    }
  }

  .trigger {
    @apply bg-theme-tableBg rounded w-full h-full p-3 text-theme-muted hover:text-theme-highlighted hover:bg-opacity-70;

    @include state(down) {
      @apply bg-theme-bg border border-theme-muted;
    }
  }

  .img {
    @apply max-h-full;
  }

  .bucket {
    @apply hidden flex-wrap fixed min-w-[10rem] bg-theme-bg border border-theme-muted rounded right-0 z-10 px-4 py-2 text-sm flex-col -translate-x-full w-fit;
    top: calc(100% + 0.5rem - 1px); // 1px accounts for border in table Row

    & > * {
      @apply py-2;
    }

    :not(:last-child) {
      @apply border-b border-dotted border-theme-muted;
    }
  }
}
