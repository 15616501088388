// @import '~@pgl-apps/styles/local.scss';
@import '~@pgl-apps/styles/local.scss';

@include namespace {
  .arrows {
    @include state(asc) {
      @apply opacity-100;
      :global {
        .up-arrow {
          @apply text-theme-punched;
        }
      }
    }
    @include state(desc) {
      @apply opacity-100;
      :global {
        .down-arrow {
          @apply text-theme-punched;
        }
      }
    }
  }
}
