// // @import '~@pgl-apps/styles/local.scss';
@import '~@pgl-apps/styles/local.scss';

// .heading {
//   font-size: 1rem;
//   font-weight: 200;
//   line-height: 1.2;
//   margin-bottom: 1em;

//   &__page {
//     font-size: 3rem;
//   }

//   &__section {
//     font-size: 2.5rem;
//   }

//   &__article {
//     font-size: 1.5rem;
//   }

//   &__paragraph {
//     line-height: 1.5;
//   }
// }

.heading {
  @apply text-base font-medium leading-snug;
  font-family: $heading-font-family;

  &__page {
    @apply text-xl;
  }

  &__section {
    @apply text-lg;
  }

  &__article {
    @apply text-lg;
  }

  &__paragraph {
    @apply leading-normal text-sm;
  }
}
