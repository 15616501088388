@import '~@pgl-apps/styles/local.scss';

.wrapper {
  background: map-get($colors-layouts, 'background');
}

.bg {
  // mvp - this will maybe move to each btn
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: map-get($colors-layouts, 'primary-gradient');
  transform: skewX(-45deg);
  transform-origin: top right;
}

.btns {
  position: relative;
  margin-right: 3rem;
}
