// --------------------- ===
//  NAMESPACE
// ---------------------
// Use to add a level of specificity
@mixin namespace {
  :global(.kap-app) {
    @content;
  }
}

// --------------------- ===
//  LAYOUTS
// ---------------------
@mixin divider-border {
  border-bottom: 1px dotted rgb(var(--color-text-muted));
}

// --------------------- ===
//  SCSS BUILDERS
// ---------------------
@mixin state($state) {
  &__#{$state} {
    @content;
  }
}

// --------------------- ===
//  ANIMATIONS
// ---------------------
@mixin standard-transition($prop: all) {
  transition: 0.35s $prop;
}

// --------------------- ===
//  NEUMORPHIC
// ---------------------
@mixin neumorphic-shadow(
  $depth: map-get($neu-shadow-depth, 'normal'),
  $isDown: false,
  $isSvg: true
) {
  $blur: $depth * 1.25;
  $lightColor: map-get($colors-translucent, 'white-100');
  $darkColor: map-get($colors-decoration, 'ghost');
  $upColor: if($isDown, $darkColor, $lightColor);
  $downColor: if($isDown, $lightColor, $darkColor);
  @if $isSvg {
    filter: drop-shadow($depth $depth $blur $downColor)
      drop-shadow((-$depth) (-$depth) $blur $upColor);
  } @else {
    box-shadow: $depth $depth $blur $downColor,
      (-$depth) (-$depth) $blur $upColor;
  }
}

// --------------------- ===
//  TEMP UNTIL WE GET OFF BOOTSTRAP
// ---------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$mobile-breakpoint: 'md';

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
