// @import '~@pgl-apps/styles/local.scss';
@import '~@pgl-apps/styles/local.scss';

.wrapper {
  @apply w-full overflow-hidden hidden col-span-full;
  padding-left: $table-padding-x;
  padding-right: $table-padding-x;

  // @include state(expand) {
  //   flex-basis: calc(100% + $table-select-width);

  //   &.wrapper__select {
  //     flex-basis: calc(100% + $table-select-width + $table-select-width);
  //   }
  // }

  // @include state(select) {
  //   flex-basis: calc(100% + $table-select-width);
  //   margin-left: -$table-select-width;
  // }
}
