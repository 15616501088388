@import '~@pgl-apps/styles/local.scss';

.poolWrapper {
  display: flex;
  align-items: center;
  font-weight: 700;

  .icon {
    height: 20px;
    margin-right: 10px;

    img {
      max-height: 100%;
      width: auto;
    }
  }
}

.lockedWrapper {
  color: var(--color-text-base);
  opacity: 0.5;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;
  }

  img {
    width: 0.5rem;
  }
}
