// @import '~@pgl-apps/styles/local.scss';
@import '~@pgl-apps/styles/local.scss';

.wrapper {
  @apply transition-transform absolute top-0 right-0 flex;
  width: $table-dropdown-width;
  height: $table-row-height;

  @include state(down) {
    transform: scale(-1);
  }

  @include state(action) {
    right: calc(#{$table-action-width} + #{$table-action-margin});
  }
}
