@import '~@pgl-apps/styles/local.scss';

.wrapper {
  background: map-get($colors-dark-layout, 'background');
  color: map-get($colors-dark-layout, 'text');
  border-radius: map-get($radii, 'card');
  padding: 30px 15px;
  margin-bottom: 1rem;
}

.header {
  @include divider-border;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-color: map-get($colors-text, 'silver');
}

.icon {
  height: 40px;
  margin-right: 15px;

  img {
    max-height: 100%;
    width: auto;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 1rem;
}
