@import '~@pgl-apps/styles/local.scss';

.countWrapper {
  position: absolute;
  bottom: 0;
  text-align: center;
  pointer-events: none; // allow user to grab slider
  touch-action: none; // allow user to grab slider
}

.week {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}

.arrow {
  flex: 0 0 10px;
  pointer-events: auto; // bring interaction back
  touch-action: auto; // bring interaction back
}

.weekCount {
  font-size: map-get($font-sizes, 'lg');
  color: map-get($colors-text, 'dark-gold');
}
