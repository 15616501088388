.filterTag {
  font-size: small;
  background-color: var(--accent);
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 15px;
  max-width: 12rem;
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  margin-right: 5px;
}

.labelText {
  @apply text-theme-base w-full;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
}

.colorChip {
  min-height: 7px;
  min-width: 7px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid currentColor;

  &__active {
    background-color: currentColor;
  }
}
