.filterItem {
  @apply h-9;
  :global {
    .fill {
      @apply fill-transparent;
    }
    .stroke {
      @apply fill-theme-muted;
    }
  }

  &__active {
    @apply bg-theme-containerBg text-theme-highlighted;
    :global {
      .fill {
        @apply fill-theme-highlighted;
      }
      .stroke {
        @apply fill-transparent;
      }
    }
  }
}
