@import '~@pgl-apps/styles/local.scss';

.line {
  fill: none;
  stroke: #231f20;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 10px;
}

.circle {
  fill: #231f20;
}
